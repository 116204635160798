<template>
	<v-container fluid style="max-width: 1600px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('events.events') }}</span>
		</div>
		<div class="mx-2 my-3">
			<span class="text-h5">{{ $t('events.searchEvents') }}</span>
		</div>

		<v-divider class="my-2" />

		<div class="d-flex align-center">
			<v-icon class="mx-4"> mdi-magnify </v-icon>
			<v-text-field
				v-model="title"
				:placeholder="`${$t('search.search')}: ${$t('auth.name')}, ${$t('companies.company')}, ${$t('companies.location')}`"
			/>
			<v-dialog v-model="filtersEditMode" max-width="1000px" @click:outside="filtersSwitchEditMode()">
				<template v-slot:activator="{ on }">
					<v-btn color="secondary" v-on="on" elevation="2" rounded small text>
						<v-icon class="mx-4"> mdi-filter </v-icon>
						{{ $t('search.filter') }}
					</v-btn>
				</template>
				<v-card rounded="xl">
					<v-card-title>
						<span class="headline">{{ $t('search.filters') }}</span>
						<v-spacer />
						<v-btn icon color="secondary" @click="filtersSwitchEditMode()">
							<v-icon class="mx-4"> mdi-close </v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text>
						<v-row>
							<v-col cols="12" md="6" sm="12">
								<v-select
									v-model="filters.eventTypes"
									:label="$t('events.eventType')"
									:items="availableEventTypes"
									item-text="name"
									item-value="id"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
								/>
							</v-col>
							<v-col cols="12" md="6" sm="12">
								<v-text-field v-model="filters.location" :label="$t('companies.location')" outlined rounded hide-details="auto" class="pb-0" />
							</v-col>
							<v-col cols="12" md="12" sm="12">
								<v-autocomplete
									v-model="filters.companies"
									:label="$t('companies.company')"
									:items="availableCompanies"
									item-text="name"
									item-value="name"
									chips
									deletable-chips
									multiple
									return-object
									outlined
									rounded
									hide-details="auto"
									class="pb-0"
									clearable
								/>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>

		<v-fade-transition group hide-on-leave>
			<template v-if="loadingEvent">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="events.length">
				<v-row key="filteresResults" no-gutters>
					<v-col cols="12" xl="3" lg="4" md="6" sm="12" v-for="event in filteredEvents" :key="`event${event.id}`" class="px-1 d-flex flex-column">
						<EventSearchCard :event="event" />
					</v-col>
				</v-row>

				<template v-if="!filteredEvents.length">
					<h4 class="m-4 text-center" key="noResultsCriteria">
						{{ $t('search.noResultsCriteria') }}
					</h4>
				</template>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

// generic accent removal from input string, add any missing characters
function removeAccents(input) {
	if (!input) return ''
	const letters1 = 'äáàâăëéèêĕíìüúùûŭöóòôŏÄÁÀÂĂËÉÈÊĔÜÚÙÛŬÖÓÒÔŎßșȘ'
	const letters2 = 'aaaaaeeeeeiiuuuuuoooooAAAAAEEEEEUUUUUOOOOOssS'
	const patternLetters = new RegExp('[' + letters1 + ']', 'g')
	const lookupLetters = {}
	letters1.split('').forEach(function (letter, i) {
		lookupLetters[letter] = letters2[i]
	})
	const letterTranslator = function (match) {
		return lookupLetters[match] || match
	}
	return input.replace(patternLetters, letterTranslator)
}

export default {
	metaInfo() {
		return {
			title: this.$t('events.events'),
		}
	},
	data() {
		return {
			filtersEditMode: false,
			title: '',
			filters: {
				eventTypes: [],
				companies: [],
				location: '',
			},
		}
	},
	components: {
		EventSearchCard: () => import('@/components/events/EventSearchCard.vue'),
	},
	computed: {
		filteredEvents() {
			return this.events.filter(
				(event) =>
					!event.applied &&
					(!this.filters.eventTypes.length || this.filters.eventTypes.map((e) => e.id).includes(event.type.id)) &&
					(!this.filters.companies.length || this.companiesName.includes(event.company.name) || this.companiesName.includes(event.companyName)) &&
					removeAccents(event.info.location).toLowerCase().includes(removeAccents(this.filters.location).toLowerCase()) &&
					(removeAccents(event.title).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.info.location).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.company.name).toLowerCase().includes(removeAccents(this.title).toLowerCase()) ||
						removeAccents(event.companyName).toLowerCase().includes(removeAccents(this.title).toLowerCase()))
			)
		},
		companiesName() {
			return this.filters.companies.map((e) => e.name)
		},
		...mapGetters({
			events: 'events/events',
			loadingEvent: 'events/loading',
			availableCompanies: 'companies/companies',
			availableEventTypes: 'events/eventTypes',
		}),
	},
	created() {
		this.fetchEventTypes()
		this.fetchCompaniesFilter()
		this.setLoadingEvent(true)
		this.fetchEvents().then(() => {
			this.setLoadingEvent(false)
		})
	},
	methods: {
		filtersSwitchEditMode() {
			this.filtersEditMode = !this.filtersEditMode
		},
		...mapActions('companies', ['fetchCompaniesFilter']),
		...mapActions('events', ['setLoadingEvent', 'fetchEvents', 'fetchEventTypes']),
	},
}
</script>
